import React, { useEffect, useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  BellIcon,
  CheckGreenCircle,
  LogOutIcon,
  LogoSmallIcon,
  MoreDotIcon
} from 'assets';
import { SideBarListType } from 'models/enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from 'styles/colors';
import {
  CreateIcon,
  CreateActiveIcon,
  GroupsActiveIcon,
  GroupsIcon,
  HomeActiveIcon,
  HomeIcon,
  NotificationIcon,
  SettingsIcon,
  NotificationActiveIcon
} from 'assets/icon';
import AvatarImg from 'pages/components/avatarImg';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { RootState } from 'store';
import { setLogIn } from 'store/authSlice';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AdminIconButton from 'pages/components/iconButton';
import { useTranslation } from 'react-i18next';
import { userProfileUrl } from 'utilities/userImageURL';
import { ApiPaths } from 'models/apiPaths';
import {
  axiosPostUserDeleteRequest,
  fetchUserDetails,
  getNotificationCount
} from 'services/api';
import ConfirmationModal from 'pages/components/confirmationModal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DialogActionWall from 'pages/components/dialogActionWall';
import isTokenExpired from 'utilities/authTokenValidation';
import { use } from 'i18next';

const listItems = [
  {
    title: 'Home',
    active: true,
    count: 2,
    link: 'home'
  },
  {
    title: 'Groups',
    active: false,
    count: 0,
    link: 'groups'
  },
  {
    title: 'Create',
    active: false,
    count: 0,
    link: 'createPost'
  },
  {
    title: 'Notification',
    active: false,
    count: 0,
    link: 'notification'
  }
];
interface IOpenDialog {
  open: boolean;
  message?: string;
  label?: string;
  userId?: string;
  username?: string;
  loaderBtn?: boolean;
  confirmTxt?: boolean;
}
interface IOpenDialog {
  open: boolean;
  id?: string;
  loaderBtn?: boolean;
  successModal?: boolean;
}
const SideBar = () => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userProfile, authToken }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );
  const [confirmModal, setConfirmModal] = React.useState<IOpenDialog>();
  const [isNotification, setIsNotification] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<IOpenDialog>({
    open: false,
    id: '',
    loaderBtn: false,
    successModal: false
  });

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const logOutClick = () => {
    setOpenDialog((pv: any) => ({
      ...pv,
      successModal: true
    }));
    setTimeout(() => {
      dispatch(
        setLogIn({
          authToken: null,
          isLoggedIn: false
        })
      );
      navigate('');
    }, 1000);
  };

  const userProfileDetails = async () => {
    const userProfile = await fetchUserDetails(ApiPaths.ME);
    dispatch(
      setLogIn({
        isLoggedIn: true,
        authToken: authToken,
        userProfile: {
          ...userProfile
        }
      })
    );
  };

  const notificationHandler = async () => {
    try {
      const notification = await getNotificationCount();
      if (notification) {
        Number(notification?.unreadNotifications) > 0
          ? setIsNotification(true)
          : setIsNotification(false);
      }
    } catch (error: unknown) {
      setIsNotification(false);
    }
  };

  useEffect(() => {
    userProfileDetails();
  }, [authToken]);

  useEffect(() => {
    if (userProfile?.notificationCount?.length > 0) {
      const readCountValue =
        userProfile?.notificationCount.filter((x: any) => x > 0).length > 0;
      readCountValue && notificationHandler();
    }
  }, [userProfile?.notificationCount]);

  // isToken Expired
  useEffect(() => {
    notificationHandler();
    const isTokenExp = isTokenExpired(authToken);
    isTokenExp &&
      dispatch(
        setLogIn({
          authToken: null,
          isLoggedIn: false
        })
      );
  }, [location]);

  const iconList = (type: string, active: boolean) => {
    switch (type) {
      case SideBarListType.home:
        return active ? <HomeActiveIcon /> : <HomeIcon />;
      case SideBarListType.groups:
        return active ? <GroupsActiveIcon /> : <GroupsIcon />;
      case SideBarListType.create:
        return active ? <CreateActiveIcon /> : <CreateIcon />;
      case SideBarListType.notification:
        return active ? (
          !isNotification ? (
            <BellIcon
              style={{
                width: 32,
                height: 32,
                marginLeft: '-4px',
                fill: colors.text.primaryTheme,
                strokeWidth: 0
              }}
            />
          ) : (
            <NotificationActiveIcon />
          )
        ) : !isNotification ? (
          <BellIcon
            style={{
              width: 32,
              height: 32,
              marginLeft: '-4px'
            }}
          />
        ) : (
          <NotificationIcon />
        );
      case SideBarListType.settings:
        return active ? <SettingsIcon /> : <SettingsIcon />;
      default:
        return false;
    }
  };
  const itemSelected = (link: string) => {
    const pathNames = location.pathname.split('/').filter((x) => x);
    return pathNames[0] === link ? true : false;
  };

  const userNameActive = (userId: string) => {
    const pathId = location.pathname.split('/').filter((x) => x)[1];
    return userId === pathId;
  };

  const buttonProps = (link: string) => ({
    selected: itemSelected(link),
    onClick: () => {
      navigate(link);
    }
  });

  const accountDelete = async () => {
    setConfirmModal((pv) => ({
      ...pv,
      open: true,
      loaderBtn: true
    }));
    try {
      const payload = {
        reason: 'Request to Delete Account'
      };
      await axiosPostUserDeleteRequest(payload);
      // console.log('deleteReq', deleteReq);
    } catch (err) {
    } finally {
      setConfirmModal((pv) => ({
        ...pv,
        open: true,
        loaderBtn: false,
        message: `The delete account request is sent successfully. Your account is scheduled for deletion within next 30 days.`,
        confirmTxt: false
      }));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          height: 70,
          display: 'flex',
          alignItems: 'center',
          margin: '0 auto',
          cursor: 'pointer'
        }}
        onClick={() => navigate('./home')}
      >
        <LogoSmallIcon />
      </Box>
      <List sx={{ margin: '0 auto', width: { md: 200 } }}>
        {listItems.map((_itm, index) => (
          <ListItem
            key={`${index}_list`}
            {...buttonProps(_itm.link)}
            sx={{
              cursor: 'pointer',
              height: 50,
              justifyContent: 'center',
              '&.Mui-selected': {
                background: colors.background.white
              }
            }}
          >
            <ListItemIcon
              sx={{
                '&.MuiListItemIcon-root': {
                  xs: {
                    minWidth: 'auto'
                  },
                  md: {
                    minWidth: 56
                  }
                }
              }}
            >
              {iconList(_itm.title, itemSelected(_itm.link))}
            </ListItemIcon>

            <ListItemText
              primary={_itm.title}
              primaryTypographyProps={{
                color: itemSelected(_itm.link)
                  ? colors.text.primaryTheme
                  : colors.text.primaryBlack,
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: itemSelected(_itm.link) ? '700' : '400',
                lineHeight: 'normal',
                display: { xs: 'none', md: 'block' }
              }}
            />
          </ListItem>
        ))}
      </List>
      <Box
        id='userDetails'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 'auto 0 16px',
          flexDirection: { xs: 'column', lg: 'row' }
        }}
      >
        <Box
          className='flexCenterJustify'
          ml={{ lg: 2 }}
          onClick={() => navigate(`/profile/${userProfile?.id}`)}
          sx={{ cursor: 'pointer', flexDirection: { xs: 'column', lg: 'row' } }}
        >
          <AvatarImg
            enableLetterImg={userProfile?.userImage ? false : true}
            src={
              userProfile?.userImage
                ? userProfileUrl(userProfile?.userImage.id)
                : ''
            }
            alt='user profile'
            id='user_profile'
            name={userProfile?.username}
            width={36}
            height={36}
            nameStyle={{
              fontSize: 14
            }}
            style={{
              borderRadius: '50%',
              background: colors.text.approxLightShade,
              objectFit: 'cover'
            }}
          />
          <Box display={{ xs: 'none', md: 'block' }}>
            <TypographyWithI18N
              className='bodyTextBold'
              i18nKey={userProfile?.username}
              sx={{
                pl: 1,
                textTransform: 'capitalize',
                color: userNameActive(userProfile?.id)
                  ? colors.text.primaryTheme
                  : colors.text.primaryBlack
              }}
            />
            <TypographyWithI18N
              i18nKey={userProfile?.email
                ?.charAt(0)
                ?.toUpperCase()
                ?.concat(userProfile?.email.slice(1))}
              sx={{
                pl: 1
              }}
            />
          </Box>
        </Box>

        {confirmModal?.open && (
          <ConfirmationModal
            open={confirmModal.open}
            message={confirmModal.message}
            title={'Request to Delete Account'}
            successLabel={'Delete'}
            loaderBtn={confirmModal.loaderBtn}
            confirmTxt={confirmModal.confirmTxt}
            onSuccess={accountDelete}
            onExit={() =>
              setConfirmModal((pv) => ({
                ...pv,
                open: false
              }))
            }
          />
        )}

        <AdminIconButton
          id='long-button'
          aria-label='more'
          onClick={handleClick}
          icon={<MoreDotIcon />}
        />

        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem
            onClick={() => {
              setConfirmModal((pv) => ({
                ...pv,
                open: true,
                message: `Are you sure you want to delete your account? \n This action cannot be undone.`,
                confirmTxt: true
              }));
            }}
          >
            <ListItemIcon>
              <LogOutIcon fontSize='small' />
            </ListItemIcon>
            Request to delete account
          </MenuItem>

          <MenuItem
            onClick={() => {
              setOpenDialog({
                open: true
              });
            }}
          >
            <ListItemIcon>
              <LogOutIcon fontSize='small' />
            </ListItemIcon>
            {t('logOut')}
          </MenuItem>
        </Menu>
      </Box>
      {openDialog.open && (
        <DialogActionWall
          open={openDialog.open}
          title={openDialog.open && !openDialog.successModal ? 'Logout' : ''}
          dialogTitleSxProps={{
            textAlign: 'center'
          }}
          dialogActionsSxProps={{
            justifyContent: 'center'
          }}
          onSuccess={logOutClick}
          onCancel={() =>
            setOpenDialog((pv: any) => ({
              ...pv,
              open: false
            }))
          }
          onSuccessBtnColor={'error'}
          loaderBtn={openDialog.loaderBtn}
          successLabel={'Logout'}
          dialogSuccessSxProps={{
            opacity: openDialog.loaderBtn ? 0.6 : 1,
            pointerEvents: openDialog.successModal ? 'none' : ''
          }}
          hideBtn={!openDialog.successModal}
          closeIconButton={openDialog.successModal}
          onClose={() => {
            setOpenDialog((pv: any) => ({
              ...pv,
              open: false,
              successModal: false
            }));
          }}
        >
          {openDialog.successModal ? (
            <Box className='flexCenterJustify flexCenterColumn'>
              <CheckGreenCircle />
              <TypographyWithI18N
                mt={3}
                className='headerBold'
                color={colors.text.primaryBlack}
                i18nKey={'You have successfully logged out from 4um.'}
              />
            </Box>
          ) : (
            <TypographyWithI18N
              textAlign={'center'}
              i18nKey={'Are you sure you want to logout the 4um?'}
            />
          )}
        </DialogActionWall>
      )}
    </Box>
  );
};

export default SideBar;
